import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/MainLayout"
import Seo from "../components/WebSeo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "./galley.module.scss"

const Gallery = ({ data }) => {
  const galleryInfo = data.allMarkdownRemark.nodes

  return (
    <Layout>
      <Seo title="Gallery" />
      <div className={styles.container}>
        {galleryInfo.map(info => {
          const thumb = getImage(
            info.frontmatter.thumb.childImageSharp.gatsbyImageData
          )
          return (
            <Link
              to={`/projects/${info.frontmatter.slug}`}
              key={info.id}
              className={styles.section}
            >
              <h3>{info.frontmatter.title}</h3>
              <GatsbyImage image={thumb} alt="thumb" />
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas
                ab illum suscipit non tenetur voluptatibus labore consequuntur
                beatae, ratione nemo? non tenetur voluptatibus labore
                consequuntur beatae, ratione nemo?
              </p>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quas
                ab illum suscipit non tenetur voluptatibus labore consequuntur
                beatae, ratione nemo?
              </p>
            </Link>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/(projects)/" } }
    ) {
      nodes {
        frontmatter {
          title
          slug
          date
          thumb {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        id
      }
    }
  }
`

export default Gallery
